html,
body,
.introview {
    min-height: 100vh;
}

.top-nav-collapse {
    background-color: #6351ce !important;
}

.navbar:not(.top-nav-collapse) {
    background: black !important;
}

.active-link {
    color: #6351ce !important;
    background-color: black !important;
}

.toast-center-center {
    top: 50%;
    left: 50%;
    margin: 0 0 0 -150px;
}

mdb-navbar ul li a:hover {
    color: black !important;
    background-color: white !important;
}

.rgba-gradient {
    background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(72, 15, 144, 0.4) 100%);
    background: -webkit-gradient(linear, 45deg, from(rgba(0, 0, 0, 0.7), rgba(72, 15, 144, 0.4) 100%));
    background: linear-gradient(to 45deg, rgba(0, 0, 0, 0.7), rgba(72, 15, 144, 0.4) 100%);
}

h6 {
    line-height: 1.7;
}

#main-logo {
    height: auto;
}

#submitbtn {
    background-color: #6351ce !important;
}

#learn-more-link {
    color: #6351ce !important;
    text-decoration: underline;
    font-weight: bold;
}

.md-toast-success {
    background-color: #6351ce !important;
}

.namfel-toast-title-success {
    background-color: #6351ce !important;
}

.namfel-toast-title-error {
    background-color: red !important;
}

.namfel-toast-message {
    background-color: black !important;
    padding-top: 50px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
    #main-logo {
        height: 50px;
    }

    .navbar-nav {
        background-color: black;
    }

    ul>li>a {
        color: white !important;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 767px) {
    #main-logo {
        height: 65px;
    }

    .navbar-nav {
        background-color: black;
    }

    ul>li>a {
        color: white !important;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
    .navbar:not(.top-nav-collapse) {
        background: #6351ce !important;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}